import { BASE_URL, ENDPOINTS, get, post } from '@/api/mainApi';
import Vue from 'vue';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { AxiosError } from 'axios';
import { BusinessSettings } from '@/interfaces/business/businessSettings';
import { PersonalDataProcessingAgreementType } from '@/enums/personalDataAgreementType';
import { Agreement, AgreementType } from '@/interfaces/depot/agreement';

export class AgreementRepository {
  constructor(private vueInstance: Vue) {
  }

  public async depotHasDepotAgreementOfType(depotId: number, type: AgreementType, onlySigned: boolean = false): Promise<boolean> {
    try {
      let url = `${ENDPOINTS.DEPOTS}/${depotId}/${ENDPOINTS.AGREEMENTS}/exists/${type}`
      if(onlySigned)
      {
        url += `?onlySigned=true`
      }
      const response = await get(url);
      return Promise.resolve(true);
    } catch (error) {
      if (error.status === 404 || error.status === 406) {
        return Promise.resolve(false);
      }
      this.handleError(error, `Misslyckades med att hämta status: ${error.data.msg || 'Okänt fel'}`);
      return Promise.reject(error);
    }
  }

  public async getDepotAgreements(depotId: number, businessId: number): Promise<Agreement> {
    try {
      return await get(ENDPOINTS.DEPOTS + "/" + depotId + "/" + ENDPOINTS.AGREEMENTS, {businessId: businessId});
    } catch (error) {
      this.handleError(error, `Något gick fel när avtal skulle hämtas. Kontakta support om problemet kvarstår: ${error.data.msg || 'Okänt fel'}`);
      return Promise.reject(error);
    }
  }

  private setSnackbar(message: string, color: 'error' | 'success') {
    const snackbar: Snackbar = {
      active: true,
      color: color,
      text: message,
      timeout: 10000,
      action: null,
    };
    this.vueInstance.$store.commit('setSnackbar', snackbar);
  }

  private handleError(error: AxiosError, errorMessage: string) {
    console.error(error);
    if (error.response?.status === 403) {
      this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
    } else {
      this.setSnackbar(errorMessage, 'error');
    }
  }
}
