


























import Vue from 'vue';
import { Depot, Status, StatusColor, StatusDescription, StatusStr } from '@/interfaces/depot/depot';
import { Snackbar } from '@/interfaces/presentation/snackbar';
import { Dialog } from '@/interfaces/presentation/dialog';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { ENDPOINTS, put } from '@/api/mainApi';
import { BankidSignatureType } from '@/interfaces/bankid/bankidSignatureType';
import { SigningInProgressState } from '@/interfaces/bankid/signingInProgressState';
import { pidPattern } from '@/validators';
import validator from 'validator';
import { getParamValue } from '@/utils/generalUtil';
import dayjs, { Dayjs } from 'dayjs';
import { AgreementRepository } from '@/repositories/agreementRepository';
import { AgreementTypeValue } from '@/interfaces/depot/agreement';
import matches = validator.matches;
import { hasOngoingSignatureAction } from '@/helpers/signatureHelper';
import { statusIsDeceased } from '@/helpers/statusHelper';


export default Vue.extend({
  props: {
    depot: Object as () => Depot,
    height: {
      type: String,
      default: "120px"
    },
    color: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      statusStr: StatusStr,
      statusDescription: StatusDescription,
      statusColor: StatusColor,
      loading: false,
      agreementRepository: new AgreementRepository(this),
      hasDepotAgreement: true,
      signingSourceId: "840a2509-ee34-414f-bc7b-18cf4460d605"
    }
  },
  computed: {
    showAsDigitalLifeArchive(): boolean {
      return this.hasDigitalLifeArchive() && !this.hasDepotAgreement;
    },
    showPlannedArchivalDate(): boolean {
      return !!this.depot.plannedToBeArchivedAt;
    },
    daysUntilArchivalText(): string {
      const daysUntilPlannedArchival = this.daysUntilPlannedArchival;
      if (daysUntilPlannedArchival < 0) {
        return 'Ska arkiveras';
      } else if (daysUntilPlannedArchival === 0) {
        return 'Arkiveras idag';
      } else if (daysUntilPlannedArchival === 1) {
        return 'Arkiveras om 1 dag';
      } else {
        return `Arkiveras om ${daysUntilPlannedArchival} dagar`;
      }
    },
    daysUntilPlannedArchival(): number | undefined {
      const plannedArchivalDate: Dayjs = this.$dayjs(this.depot.plannedToBeArchivedAt).startOf('day');
      const today: Dayjs = this.$dayjs().startOf('day');
      return plannedArchivalDate.diff(today, 'day');
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    statusIsArchived() {
      let status: Status = this.depot.status
      return status === Status.ARCHIVED
    },
    statusIsDeceased(): boolean {
      const status: Status = this.depot.status
      return statusIsDeceased(status);
    },
    showDeceasedRegistrationDate(): boolean {
      return !!this.depot && !!this.depot.deceasedRegistrationDate && this.statusIsDeceased;
    },
    showDeceasedDate(): boolean {
      return !!this.depot && !!this.depot.deceasedDate && !this.depot.deceasedRegistrationDate && this.statusIsDeceased;
    },
    markDeceasedEnabled() {
      // only if not already deceased and user office has keepsakes
      // TODO - should be business level not office level
      return this.stateUser.office && !this.statusIsDeceased && !this.statusIsArchived && this.depot.keepsakes.findIndex(item => item.office.id === this.stateUser.office.id) >= 0
    },
    item: {
      get(): Depot {
        return this.depot
      },
      set(val:Depot) {
        this.$emit('update:depot', val)
      }
    },
    ongoingBeingMarkedAsDeadSigningInProgress(): boolean {
      return hasOngoingSignatureAction(this.signingInProgress, BankidSignatureType.MARKED_AS_DEAD, this.signingSourceId)
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress
    },
    usesBankId() {
      let soc = this.stateUser.loginCredentials.socialSecurityNumber
      return soc != null && matches(soc, pidPattern)
    }
  },
  mounted() {
    if (this.ongoingBeingMarkedAsDeadSigningInProgress) {
      let signingDepotId: number = this.signingInProgress.depotId;
      if (signingDepotId === this.depot.id) {
        this.markAsDeceased(this.getBankIdSignatureIdParam())
      }
    }
    this.evaluateIfDepotHasDepotAgreement();
  },
  methods: {
    hasDigitalLifeArchive(): boolean {
      for (let depotKeepsakeContainer of this.depot.keepsakes) {
        for (let keepsake of depotKeepsakeContainer.keepsakes) {
          if (keepsake.keepsakeType.digitalLifeArchive) {
            return true
          }
        }
      }
      return false;
    },
    confirmMarkAsDeceased() {
      if (this.usesBankId) {
        // this delays the actual extradition since we shall navigate away
        this.setSigningStatusInStore()
      }

      let dialog: Dialog = {
        active: true,
        actionClick: this.markAsDeceased,
        title: "Anmäl dödsfall",
        text: "Beställare/Efterlevande har idag meddelat att " + this.depot.name + " avlidit. " +
            "\n\n När du signerar kommer inget påminnelsemail när SPAR registrerat dödsfallet. " +
            "\n\n Kansliet ser också då att du påbörjat arbetet" +
            "\n\n OBS! Samtliga dokument skall lämnas ut och alla åtgärder i Dokumentbevakningssystemet diarieförs i händelseloggen.",
      };
      this.$store.commit('setCredentialsConfirmDialog', dialog);
    },
    async evaluateIfDepotHasDepotAgreement() {
      // Only legacy depots may not have depot agreements
      if (!!this.depot.legacyId) {
        this.hasDepotAgreement = await this.agreementRepository.depotHasDepotAgreementOfType(this.depot.id, AgreementTypeValue.DEPOT_AGREEMENT);
      } else {
        this.hasDepotAgreement = true;
      }
    },
    async markAsDeceased(bankidSignatureId: string) {
      let bankIdSignatureParam = ""
      if (bankidSignatureId) {
        bankIdSignatureParam += "?signatureId=" + bankidSignatureId;
      }

      this.loading = true
      try {
        let response = await put(ENDPOINTS.DEPOTS + "/" + this.depot.id + "/" + ENDPOINTS.DECEASED + bankIdSignatureParam, {})
        if (response && response.id) {
          this.item = response
          let snackbar: Snackbar = {
            active: true,
            color: "success",
            text: "Status ändrad!",
            timeout: 6000,
            action: null
          };
          this.$store.commit('setSnackbar', snackbar);
          this.menu = false
        }
      } catch (e) {
        console.error(e)
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Något gick fel när dokumentet skulle sparas. Kontakta support om problemet kvarstår",
          timeout: 10000,
          action: null
        };
        this.$store.commit('setSnackbar', snackbar);
      } finally {
        // remove signing just to be safe
        this.removeSigning();
        await this.$router.go(0);
      }
      this.loading = false
    },
    removeSigning() {
      this.$store.commit('removeSigningInProgress')
    },
    setSigningStatusInStore() {
      // prepare the signing by adding data for it, but it is not yet ready
      let signingInProgressState: SigningInProgressState = {
        bankidSignatureType: BankidSignatureType.MARKED_AS_DEAD,
        depotId: this.depot.id,
        ready: false,
        sourceId: this.signingSourceId
      }
      this.$store.commit('setSigningInProgress', signingInProgressState)
    },
    getBankIdSignatureIdParam(): string | null {
      let bankIdSignatureId = getParamValue("signatureId");
      if (!bankIdSignatureId) {
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Kunde inte hitta BankID-signaturen inför markering av avliden",
          timeout: 30000,
          action: null
        };

        this.$store.commit('setSnackbar', snackbar);
      }
      return bankIdSignatureId;
    },
    currentDate(date: string) {
      return this.$dayjs(date).format("YYYY-MM-DD")
    },
  },
  filters: {
    statusStr(status, statusStr) {
      return statusStr[status]
    },
    statusDescription(status, statusDescription) {
      return statusDescription[status]
    },
  },
});
