
































import Vue from 'vue';
import { Agreement } from '@/interfaces/depot/agreement';
import DepotAgreementListItemComponent from '@/components/depot/details/agreement/DepotAgreementListItemComponent.vue';

export default Vue.extend({
  components: {
    DepotAgreementListItemComponent,
  },
  data() {
    return {};
  },
  props: {
    agreements: Array as () => Array<Agreement>,
  },
  computed: {
    items(): Array<Agreement> { // we use computed here just to get some intellisense from interface
      return this.agreements;
    },
  },
  methods: {
    refreshAgreements() {
      this.$emit('refreshAgreements');
    },
    signedAgreement(data) {
      this.$emit('signedAgreement', data);
    },
  },
});
