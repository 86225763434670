
















































































































































































































import Vue from 'vue';
import {Depot} from '@/interfaces/depot/depot';
import KeepsakeOfficeTransferDialogComponent from '@/components/keepsake/KeepsakeOfficeTransferDialogComponent.vue';
import DepotKeepsakesListComponent from '@/components/depot/details/keepsake/DepotKeepsakesListComponent.vue';
import {DepotKeepsakeContainer} from '@/interfaces/keepsake/depotKeepsakeContainer.ts';
import {Keepsake} from '@/interfaces/keepsake/keepsake';
import {Business} from '@/interfaces/business/business';
import {AccessType} from '@/interfaces/keepsake/enums/accessType';
import {BASE_URL, ENDPOINTS, get, MainApi} from '@/api/mainApi';
import {Snackbar} from '@/interfaces/presentation/snackbar';
import KeepsakeExtraditionComponent from '@/components/depot/details/keepsake/KeepsakeExtraditionComponent.vue';
import {DepotKeepsake} from '@/interfaces/keepsake/depotKeepsake';
import { Agreement, AgreementTypeValue } from '@/interfaces/depot/agreement';
import {UserAccount} from '@/interfaces/useraccount/userAccount';
import DepotMiniNavComponent from '@/components/depot/details/keepsake/DepotMiniNavComponent.vue';
import {Office} from '@/interfaces/office/office';
import {SigningInProgressState} from '@/interfaces/bankid/signingInProgressState';
import {isOrgAdmin, isSysAdmin} from '@/helpers/userAccountHelper';
import {BusinessSetting} from '@/enums/businessSetting';
import dayjs from 'dayjs';

export default Vue.extend({
  components: {
    DepotKeepsakesListComponent,
    KeepsakeExtraditionComponent,
    DepotMiniNavComponent,
    KeepsakeOfficeTransferDialogComponent,
  },
  props: {
    depot: Object as () => Depot,
    readOnly: Boolean, // used for DepotReceiptView
    outlined: Boolean,
    flat: Boolean,
    refreshAgreements: Boolean,
    receipt: Boolean,
  },
  data() {
    return {
      moveKeepsakesToOfficeDialog: false,
      businessTab: 0,
      depotAgreements: [] as Array<Agreement>,
      showExtraditionDialog: false,
      showSharedDepotsNav: false,
      selectedKeepsakes: [] as Array<Number>, // ids of the keepsakes
      selectedBusiness: null as Business | null,
      agreementsDisabled: false,
    };
  },
  computed: {
    hasDepotKeepsakes(): boolean {
      return this.depot.keepsakes.length > 0
    },
    signingInProgress(): SigningInProgressState {
      return this.$store.state.signingInProgress;
    },
    isSysAdmin(): boolean {
      return isSysAdmin(this.user);
    },
    isOrgAdmin(): boolean {
      return isOrgAdmin(this.user);
    },
    selectedKeepsakesEntities(): Array<Keepsake> {
      if (this.selectedKeepsakes.length === 0) {
        return this.allKeepsakes.filter(keepsake => this.selectedBusiness.offices.map(office => office.id).indexOf(keepsake.office.id) != -1);
      }
      return this.allKeepsakes.filter(ks => this.selectedKeepsakes.find(ksId => ksId === ks.id));
    },
    totalNumberOfKeepsakes(): number {
      let businessKeepsakes: Array<DepotKeepsakeContainer> = this.depot.keepsakes;
      let total = 0;
      businessKeepsakes.forEach((bk) => {
        total += bk.keepsakes.length;
      });

      return total;
    },
    extraditionKeepsakes(): Array<Keepsake> {
      if (this.selectedKeepsakes.length && this.allKeepsakes) {
        return this.allKeepsakes.filter((a: Keepsake) => a.keepsakeType.canBeExtradited && a.office?.business && this.selectedBusiness && a.office?.business.id === this.selectedBusiness.id && this.selectedKeepsakes.includes(a.id) && !this.hasKeepsakeBeenExtradited(a));
      } else {
        return this.allKeepsakes.filter((a: Keepsake) => a.keepsakeType.canBeExtradited && a.office?.business && this.selectedBusiness && a.office?.business.id === this.selectedBusiness.id && !this.hasKeepsakeBeenExtradited(a));
      }
    },
    enabledExtradition(): boolean {
      return !(this.isEveryKeepsakePrivate || this.extraditionKeepsakes.length === 0);
    },
    depotAgreementsHaveMissingSignatures(): boolean {
      for (let agreement of this.activeDepotAgreements) {
        if (!agreement.agreementSignature) {
          return true;
        }
      }
      return false;
    },
    extraditionInfo(): string {
      let text = '';

      if (this.isEveryKeepsakePrivate) {
        text += 'Bevakningarna tillhör ett annat företag, ej åtkomst i livstiden.';
      }
      if (this.extraditionKeepsakes.length === 0) {
        text += 'Inga av de valda bevakningarna får lämnas ut. ';
      }

      return text;
    },
    friendDepots(): Array<Depot> {
      let friendDepots: Array<Depot> = [];
      let businessKeepsakes: Array<DepotKeepsakeContainer> = this.depot.keepsakes;

      businessKeepsakes.forEach((bk: DepotKeepsakeContainer) => {
        bk.keepsakes.forEach((k: Keepsake) => {
          if (k.office && this.isKeepsakeAccessPublic(k, k.office.business)) {
            k.depotKeepsakes.forEach((dk: DepotKeepsake) => {
              if (dk.depot.id !== this.depot.id && !friendDepots.some(f => f.id === dk.depot.id)) {
                friendDepots.push(dk.depot);
              }
            });
          }
        });
      });
      return friendDepots;
    },
    unselectableKeepsakeNames(): string {
      return this.allKeepsakes.filter((a: Keepsake) => this.selectedKeepsakes.includes(a.id)
        && !a.keepsakeType.canBeExtradited)
        .map((sk: Keepsake) => (sk.description)).join(', ');
    },
    user(): UserAccount {
      return this.$store.state.userSession.userAccount;
    },
    currentDate(): string {
      return dayjs().format('YYYY-MM-DD');
    },
    isUserBusinessActive(): boolean {
      const hasUserAndBusiness = this.user && this.selectedBusiness;
      if (isOrgAdmin(this.user)) {
        return hasUserAndBusiness && this.userBusiness.organization.id === this.selectedBusiness.organization.id;
      } else if (isSysAdmin(this.user)) {
        return true;
      } else {
        return hasUserAndBusiness && this.user.business.id === this.selectedBusiness.id;
      }
    },
    allKeepsakes(): Array<Keepsake> {
      let keepsakes: Array<Keepsake> = [];
      for (let depotKeepsakeContainer of this.depot.keepsakes) {
        // we need to add office here so privacy can be evaluated
        for (let keepsake of depotKeepsakeContainer.keepsakes) {
          keepsake.office = depotKeepsakeContainer.office;
        }
        keepsakes.push(...depotKeepsakeContainer.keepsakes.filter(keepsake => !(this.readOnly
          && keepsake.keepsakeType.hiddenInReceipt)));
      }
      return keepsakes;
    },
    userBusiness(): Business {
      return this.$store.state.userSession.userAccount.business;
    },
    isEveryKeepsakePrivate(): boolean {
      // checks accessType for every keepsake and determines if all of them are private
      for (let keepsake of this.allKeepsakes) {
        let publicAccess = this.isKeepsakeAccessPublic(keepsake, keepsake.office.business);
        if (publicAccess) {
          return false;
        }
      }
      return true;
    },
    activeDepotAgreements(): Array<Agreement> {
      return this.depotAgreements.filter(s => !s.archived);
    },
    businesses(): Array<Business> {
      let businesses: Array<Business> = [];
      if (this.user.office && this.depot) {
        // extract all unique businesses from the depot keepsakes
        for (let depotKeepsake of this.depot.keepsakes) {
          let foundBusiness: Business | undefined = businesses.find(b => b.id === depotKeepsake.office.business.id);
          if (!foundBusiness) {
            businesses.push(depotKeepsake.office.business);
          }
        }
        // add offices to businesses
        for (let business of businesses) {
          let officeSet = new Set<Office>();
          let offices = this.depot.keepsakes.filter(ok => ok.office.business.id === business.id);
          offices.forEach(o => officeSet.add(o.office));
          business.offices = Array.from(officeSet);
          // depot keepsakes to offices
          business.offices.forEach((o: Office, i: number) => {
            o.keepsakes = [];
            let deep: Array<DepotKeepsakeContainer> = this.depot.keepsakes.filter(k => k.office.id === o.id);
            deep.forEach(x => {
              x.keepsakes.forEach(k => o.keepsakes?.push(k));
            });

            // sort the offices so that the user office is first in the list
            if (o.business.id === this.user.business.id && o.id == this.user.office.id) {
              let officeSpliced: Office[] = business.offices.splice(i, 1);
              business.offices.unshift(officeSpliced[0]);
            }
          });
        }
      }
      return businesses;
    },
  },
  watch: {
    businesses: {
      immediate: true,
      handler(businesses: Array<Business>) {
        if (businesses.length > 0) {
          this.selectedBusiness = businesses[0];
          this.$emit('business-tab-changed', this.selectedBusiness);
        } else {
          this.selectedBusiness = this.user.business;
        }
      },
    },
    refreshAgreements(value) {
      if (value) {
        this.getDepotAgreements();
      }
    },
  },
  mounted() {
    this.getDepotAgreements();
    this.fetchDepotAgreementDisabledSetting();
  },
  methods: {
    handleKeepsakeOfficeTransfer(event) {
      this.keepsake.office = event.office;
    },
    isKeepsakeAccessPublic(keepsake: Keepsake, keepsakeBusiness: Business): boolean {
      switch (keepsake.keepsakeType.accessType) {
        case AccessType.OPEN:
          return true;
        case AccessType.OPEN_AFTER_DEATH:
          return this.userBusiness && this.userBusiness.id === keepsakeBusiness.id || this.depot.deceased;
        case AccessType.CLOSED:
          return this.userBusiness && this.userBusiness.id === keepsakeBusiness.id;
        default:
          return false;
      }
    },
    async getDepotAgreements() {
      if (this.selectedBusiness) {
        this.loading = true;
        try {
          const response = await get(ENDPOINTS.DEPOTS + '/' + this.depot.id + '/' + ENDPOINTS.AGREEMENTS, {
            businessId: this.selectedBusiness.id,
            agreementType: AgreementTypeValue.DEPOT_AGREEMENT,
          });
          if (response && response.length > 0) {
            this.depotAgreements = response;
          }
        } catch (e) {
          console.error(e);
          let snackbar: Snackbar = {
            active: true,
            color: 'error',
            text: 'Något gick fel när avtal skulle hämtas. Kontakta support om problemet kvarstår',
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
        }
        this.loading = false;
      }
    },
    async fetchDepotAgreementDisabledSetting() {
      if (this.selectedBusiness) {
        this.loading = true;
        try {
          this.agreementsDisabled = await get(`${ENDPOINTS.BUSINESSES}/${this.selectedBusiness.id}/${ENDPOINTS.SETTINGS}/${BusinessSetting.DEPOT_AGREEMENTS_DISABLED}`);
        } catch (e) {
          console.error(e);
          let snackbar: Snackbar = {
            active: true,
            color: 'error',
            text: 'Något gick fel när konfigurationen skulle hämtas. Kontakta support om problemet kvarstår',
            timeout: 8000,
            action: null,
          };
          this.$store.commit('setSnackbar', snackbar);
        }
        this.loading = false;
      }
    },
    extraditeKeepsakes() {
      this.showExtraditionDialog = true;
    },
    hasKeepsakeBeenExtradited(keepsake: Keepsake): boolean {
      if (keepsake) {
        let ownKeepsakes: DepotKeepsake[] = keepsake.depotKeepsakes.filter(dk => dk.depot.id === this.depot.id);
        return ownKeepsakes.some((depotKeepsake) => depotKeepsake.extradition);
      }
      return false;
    },
    tabChanged(val: Business) {
      if (val) {
        this.selectedBusiness = val;
        this.selectedKeepsakes = [];
        this.fetchDepotAgreementDisabledSetting();
        this.$emit('business-tab-changed', val);
      }
    },
    async handleExtradition(agreements: Array<Agreement>) {
      if (!!agreements && agreements.length > 0) {
        for (let agreement of agreements) {
          this.downloadExtraditionAgreement(agreement.id);
        }
      }
      this.$emit('handle-extradition', true);
    },
    downloadExtraditionAgreement(agreementId: number) {
      try {
        window.open(BASE_URL + '/' + MainApi + '/' + ENDPOINTS.AGREEMENTS + '/' + agreementId + '/' + ENDPOINTS.DOCUMENT + '?officeId=' + this.user.office.id + '&renderAsPdf=true', '_blank');
      } catch (e) {
        console.error(e);
        let snackbar: Snackbar = {
          active: true,
          color: 'error',
          text: 'Något gick fel när avtalet skulle öppnas. Kontakta support om problemet kvarstår',
          timeout: 8000,
          action: null,
        };
        this.$store.commit('setSnackbar', snackbar);
      }
    },
    showSharedDepotsMiniNav() {
      if (this.friendDepots.length) {
        this.showSharedDepotsNav = true;
      }
    },
    refreshDepots() {
      this.$emit('refresh-depots');
    },
    isKeepsakeAccessibleByBusiness(keepsake: Keepsake): boolean {
      return !keepsake.keepsakeType.canBeExtraditedByAllBusinesses
        && keepsake.depotKeepsakes.some((dk: DepotKeepsake) => dk.office.business.id !== this.userBusiness.id)
    },
  },
});
