










































































import Vue from 'vue';
import { Depot } from '@/interfaces/depot/depot';
import { DepotRepository } from '@/repositories/depotRepository';
import { Dialog } from '@/interfaces/presentation/dialog';
import { isHigherLevelAdmin } from '@/helpers/userAccountHelper';
import { UserAccount } from '@/interfaces/useraccount/userAccount';

export default Vue.extend({
  props: {
    depot: Object as () => Depot,
    height: {
      type: String,
      default: "120px"
    },
    color: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      editingDepotName: false,
      newDepotName: "",
      depotRepository: new DepotRepository(this)
    }
  },
  computed: {
    validName(): boolean {
      return !!this.newDepotName && this.newDepotName.trim().length > 0 && this.newDepotName !== this.depot.name
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    isAllowedToOpen(): boolean {
      return this.depot && this.depot.adminRoleRequired ? isHigherLevelAdmin(this.stateUser) : true;
    },
  },
  mounted() {
    this.newDepotName = this.depot.name
  },
  methods: {
    confirmEditDepotName() {
      if (!this.validName) {
        return;
      }
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Spara",
        title: "Bekräfta namnändring",
        text: "Är du säker på att du vill ändra namnet på depån till  " + this.newDepotName + "?",
        actionClick: () => {
          this.editDepotName()
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async editDepotName() {
      let updateResult = await this.depotRepository.updateDepotName(this.depot.id, this.newDepotName);
      if (updateResult) {
        this.depot.name = this.newDepotName;
        this.editingDepotName = false;
      }
    },
  },
});
