


















































































































































import Vue from 'vue';
import {Business} from "@/interfaces/business/business";
import {Snackbar} from "@/interfaces/presentation/snackbar";
import {UserAccount} from "@/interfaces/useraccount/userAccount";
import {Role, RoleArray, RoleStr} from "@/enums/role";
import UserAccountDetailsComponent from "@/components/useraccount/UserAccountDetailsComponent.vue";
import UserAccountFormComponent from "@/components/useraccount/UserAccountFormComponent.vue";
import {deleteRequest, ENDPOINTS, get, post} from "@/api/mainApi";

export default Vue.extend({
  components: {UserAccountDetailsComponent, UserAccountFormComponent},
  props: {
    selectedBusiness: Object as () => Business
  },
  data() {
    return {
      roles: RoleArray,
      users: [] as UserAccount[],
      selected: null as UserAccount | null,
      loading: false,
      newUser: {
        firstname: "",
        surname: "",
        email: "",
        phone: "",
        business: this.selectedBusiness,
        office: null,
        loginCredentials: {
          username: "",
          socialSecurityNumber: "",
        },
        role: Role.BUSINESS_USER,
        notificationRecipient: true,
      } as UserAccount | null,
      newDialog: false,
      emailRules: [
        v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
      ],
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
      newFormValid: false,
      selectedFormValid: false,
    }
  },
  computed: {
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    businessName(): string {
      return this.selectedBusiness ? this.selectedBusiness.name : ''
    }
  },
  watch: {
    selectedBusiness(val: Business) {

      this.selected = null;
      this.users = [];

      if (val && val.id) {
        this.newUser.business = val;
        this.getUsers(val.id);
      }
    }
  },
  methods: {
    selectUser(user) {
      this.selected = user;
      this.$emit('select', user);
      this.users.forEach(o => o.active = false);
      user.active = true;
    },
    async getUsers(businessId) {

      this.loading = true;

      get(ENDPOINTS.USER_ACCOUNTS, {businessId: businessId}
      ).then((users: Array<UserAccount>) => {
        this.users = users;
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    deleteUser(user: UserAccount) {

      this.loading = true;

      deleteRequest(ENDPOINTS.USER_ACCOUNTS + "/" + user.id)
        .then((userAccount: UserAccount) => {

          let index = this.users.findIndex((v: UserAccount) => v.id == userAccount.id);

          if (index != -1) {
            this.users.splice(index, 1)
            let snackbar: Snackbar = {
              active: true,
              color: "primary",
              text: "Inaktiverade " + userAccount.firstname,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar)

            this.selected = null
          }
        }).catch(err => {
        console.log(err.data.msg);
        let snackbar: Snackbar = {
          active: true,
          color: "error",
          text: "Misslyckades med att inaktivera användaren: " + err.data.msg,
          timeout: 10000,
          action: null
        }
        this.$store.commit('setSnackbar', snackbar)
      }).finally(() => {
        this.loading = false;
      })
    },
    getRoleText(role: string) {
      return RoleStr[role];
    },
    addUser() {
      this.$refs.newform.validate()

      if (this.newFormValid) {

        this.loading = true;

        post(ENDPOINTS.USER_ACCOUNTS, this.newUser)
          .then((user: UserAccount) => {
            this.users.push(user);

            let snackbar: Snackbar = {
              active: true,
              color: "success",
              text: `Lade till ${this.newUser.firstname} ${this.newUser.surname}`,
              timeout: 8000,
              action: null
            }
            this.$store.commit('setSnackbar', snackbar)
            this.newUser = {
              firstname: "",
              surname: "",
              email: "",
              username: "",
              role: Role.BUSINESS_USER,
              business: this.selectedBusiness,
              loginCredentials: {
                username: "",
                socialSecurityNumber: "",
              },
              notificationRecipient: true,
            }
            this.$store.commit('setSnackbar', snackbar)
          }).catch(err => {
          console.log(err.data.msg);
          let snackbar: Snackbar = {
            active: true,
            color: "error",
            text: "Misslyckades med att lägga till användare: " + err.data.msg,
            timeout: 10000,
            action: null
          }
          this.$store.commit('setSnackbar', snackbar)
        }).finally(() => {
          this.loading = false;
          this.newDialog = false
        })
      }
    }
  }
})
