

























import Vue from 'vue';
import axios from "axios";
import {FileData} from "@/interfaces/fileData";
import {BASE_URL, LOGIN_PAGE_URL} from "@/api/mainApi";

export default Vue.extend({
  components: {},
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    solo: Boolean,
    singleLine: Boolean
  },
  data() {
    return {
      uploadedFiles: null,
      fileName: "",
      loading: false,
      error: false,
      requiredRules: [
        v => !!v || 'Obligatoriskt fält'
      ],
    }
  },
  computed: {
    statusColor() {
      if (this.loading) {
        return "accent"
      }
      return this.error ? "error" : "success"
    },
    errorMessages() {
      return this.error ? ["Filen kunde inte laddas upp! Detta beror troligtvis på att du försöker ladda upp ett filformat som inte stödjs eller att filstorleken överskrider det tillåtna värdet."] : []
    },
    acceptedFiletypes() {
      return "image/*,.pdf,.csv,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }
  },
  methods: {
    fileChanged() {
      this.error = false
      if (this.uploadedFiles && this.uploadedFiles.length > 0) {
        this.uploadFile()
      }
    },
    async uploadFile() {
      this.loading = true
      this.fileName = this.uploadedFiles[0].name
      // upload the file as formData
      let formData = new FormData();
      formData.append("file", this.uploadedFiles[0]);
      let fileDataList: Array<FileData> = await axios.post(BASE_URL + "/api/files", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      }).then(response => response.data)
          .catch((error) => {
            if (error.response?.status === 401) {
              location.href = LOGIN_PAGE_URL
            }
            console.error(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      if(fileDataList && fileDataList.length > 0) {
        this.$emit('fileUploaded', fileDataList[0])
      }
      else {
        this.$emit('fileUploaded', null)
      }
    },
    fileRemoved() {
      this.$emit('fileUploaded', null)
    },
    clearFile() {
      this.$refs.fileinput.clearableCallback()
    }
  }
})
